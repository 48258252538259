import React, { useState, useEffect, useRef, useCallback } from 'react';
import './App.css';
import AWS from 'aws-sdk';
import axios from 'axios';

const staticImages = {
  '7664': '/images/7664BenBrown.png',
  '30295': '/images/30295Darcy Byrne-Jones.png',
  '7642': '/images/7642AdamTreloar.png',
  '7638': '/images/7638RoarkeSmith.png',
  '30294': '/images/30294XavierDuursma.png',
  '30657': '/images/30657LloydMeek.png',
  '30658': '/images/30658CameronGuthrie.png',
  '30296': '/images/30296BeauMcCreery.png',
  '7651': '/images/7651TeamMoment.png',
  // Add more mappings as needed
};

function MomentInfo() {
  const [images, setImages] = useState([]);
  const [clubs, setClubs] = useState([]);
  const [players, setPlayers] = useState([]);
  const [tiers, setTiers] = useState([]);
  const [collectionNames, setCollectionNames] = useState([]);
  const [collectionDates, setCollectionDates] = useState([]);
  const [selectedClub, setSelectedClub] = useState('');
  const [selectedPlayer, setSelectedPlayer] = useState('');
  const [selectedTier, setSelectedTier] = useState('');
  const [selectedCollectionName, setSelectedCollectionName] = useState('');
  const [selectedCollectionDate, setSelectedCollectionDate] = useState('');
  const [walletOwners, setWalletOwners] = useState({});
  const [loading, setLoading] = useState({});
  const [sortOrder, setSortOrder] = useState('asc');
  const [mintNumberFilter, setMintNumberFilter] = useState('');
  const [nftsForSale, setNftsForSale] = useState({});
  const [usdToAudRate, setUsdToAudRate] = useState(1);
  const [pin, setPin] = useState('');
  const [isPinCorrect, setIsPinCorrect] = useState(false);
  const [filterByJumperNumber, setFilterByJumperNumber] = useState(false); // Checkbox state

  const imagesRef = useRef(images);
  const scrollRef = useRef(null);

  useEffect(() => {
    imagesRef.current = images;
  }, [images]);

  useEffect(() => {
    const pinValidated = sessionStorage.getItem('pinValidated');
    if (pinValidated === 'true') {
      setIsPinCorrect(true);
    }
  }, []);

  useEffect(() => {
    AWS.config.update({
      region: process.env.REACT_APP_AWS_REGION,
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    });
    const dynamodb = new AWS.DynamoDB.DocumentClient();

    const fetchInitialData = async () => {
      const params = {
        TableName: 'AFLMoments',
        ProjectionExpression: 'teamOfInvolvedPlayer, tier, collectionName, collectionDate, player, playersJumperNumber'
      };

      try {
        let scanResults = [];
        let items;
        do {
          items = await dynamodb.scan(params).promise();
          items.Items.forEach((item) => scanResults.push(item));
          params.ExclusiveStartKey = items.LastEvaluatedKey;
        } while (typeof items.LastEvaluatedKey !== "undefined");

        const uniqueClubs = [...new Set(scanResults.map(item => item.teamOfInvolvedPlayer))];
        const uniqueTiers = [...new Set(scanResults.map(item => item.tier).filter(Boolean))];
        const uniqueCollectionNames = [...new Set(scanResults.map(item => item.collectionName).filter(Boolean))];
        const uniquePlayers = [...new Set(scanResults.map(item => item.player).filter(Boolean))];
        setClubs(uniqueClubs);
        setTiers(uniqueTiers);
        setCollectionNames(uniqueCollectionNames);
        setPlayers(uniquePlayers);
      } catch (err) {
        console.error('Error fetching initial data:', err);
      }
    };

    fetchInitialData();
  }, []);

  useEffect(() => {
    const fetchUsdToAudRate = async () => {
      try {
        const response = await axios.get('https://api.exchangerate-api.com/v4/latest/USD');
        setUsdToAudRate(response.data.rates.AUD);
      } catch (err) {
        console.error('Error fetching USD to AUD conversion rate:', err);
      }
    };

    fetchUsdToAudRate();
  }, []);

  useEffect(() => {
    if (selectedCollectionName) {
      const dynamodb = new AWS.DynamoDB.DocumentClient();
      const fetchCollectionDates = async () => {
        const params = {
          TableName: 'AFLMoments',
          IndexName: 'collectionName-index',
          KeyConditionExpression: 'collectionName = :collectionName',
          ExpressionAttributeValues: {
            ':collectionName': selectedCollectionName
          },
          ProjectionExpression: 'collectionDate'
        };

        try {
          const data = await dynamodb.query(params).promise();
          const uniqueCollectionDates = [...new Set(data.Items.map(item => item.collectionDate).filter(Boolean))];
          uniqueCollectionDates.sort((a, b) => new Date(a) - new Date(b));
          setCollectionDates(uniqueCollectionDates);
        } catch (err) {
          console.error('Error fetching collection dates:', err);
        }
      };

      fetchCollectionDates();
    } else {
      setCollectionDates([]);
      setSelectedCollectionDate('');
    }
  }, [selectedCollectionName]);

  const fetchMintNumbers = useCallback(async (items) => {
    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const mintNumbers = {};

    for (const item of items) {
      const params = {
        TableName: 'AFLMint',
        IndexName: 'templateId-index',
        KeyConditionExpression: 'templateId = :templateId',
        ExpressionAttributeValues: {
          ':templateId': item.templateId
        },
        ProjectionExpression: 'mintNumber, nftId, eventDate'
      };

      try {
        const data = await dynamodb.query(params).promise();
        
        // Check the checkbox state and filter based on playersJumperNumber if checked
        const filteredMintNumbers = filterByJumperNumber
          ? data.Items.filter(mintItem => mintItem.mintNumber.toString() === item.playersJumperNumber)
          : data.Items;

        mintNumbers[item.templateId] = filteredMintNumbers.map(mintItem => ({
          mintNumber: mintItem.mintNumber.toString(),
          nftId: mintItem.nftId,
          eventDate: mintItem.eventDate
        }));
      } catch (err) {
        console.error(`Error fetching mint numbers for templateId ${item.templateId}:`, err);
      }
    }

    const updatedImages = imagesRef.current.map(image => ({
      ...image,
      mintNumbers: mintNumbers[image.templateId] || []
    }));

    setImages(updatedImages);
  }, [filterByJumperNumber]);

  useEffect(() => {
    if (!selectedClub && !selectedTier && !selectedPlayer && !selectedCollectionName && !selectedCollectionDate && !filterByJumperNumber) return;

    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const filterExpressions = [];
    const expressionAttributeValues = {};

    if (selectedClub) {
      filterExpressions.push('teamOfInvolvedPlayer = :club');
      expressionAttributeValues[':club'] = selectedClub;
    }

    if (selectedTier) {
      filterExpressions.push('tier = :tier');
      expressionAttributeValues[':tier'] = selectedTier;
    }

    if (selectedPlayer) {
      filterExpressions.push('player = :player');
      expressionAttributeValues[':player'] = selectedPlayer;
    }

    if (selectedCollectionName) {
      filterExpressions.push('collectionName = :collectionName');
      expressionAttributeValues[':collectionName'] = selectedCollectionName;
    }

    if (selectedCollectionDate) {
      filterExpressions.push('collectionDate = :collectionDate');
      expressionAttributeValues[':collectionDate'] = selectedCollectionDate;
    }

    const queryParams = {
      TableName: 'AFLMoments',
      FilterExpression: filterExpressions.join(' AND '),
      ExpressionAttributeValues: expressionAttributeValues,
      ProjectionExpression: 'templateId, nftId, thumbnail, video, eventDate, player, playersJumperNumber, maxSerial, tier, collectionName, collectionDate'
    };

    const fetchImagesAndPlayers = async () => {
      try {
        let scanResults = [];
        let items;
        do {
          items = await dynamodb.scan(queryParams).promise();
          items.Items.forEach((item) => scanResults.push(item));
          queryParams.ExclusiveStartKey = items.LastEvaluatedKey;
        } while (typeof items.LastEvaluatedKey !== "undefined");

        const updatedData = scanResults.map(item => ({
          ...item,
          mintNumbers: item.mintNumbers || [],
          specialSerials: ['1', item.maxSerial?.toString() || '']
        }));

        setImages(updatedData);
        await fetchMintNumbers(updatedData);
        await fetchNftsForSale(updatedData);
      } catch (err) {
        console.error('Error fetching images and players:', err);
      }
    };

    setImages([]);
    fetchImagesAndPlayers();
  }, [selectedClub, selectedPlayer, selectedTier, selectedCollectionName, selectedCollectionDate, filterByJumperNumber, fetchMintNumbers]);

  const fetchNftsForSale = async (items) => {
    const sales = {};
    try {
      const templateIds = items.map(item => item.templateId).join(',');
      const response = await axios.get(`https://api.aflmint.com.au/api/marketplace/sale-nfts-by-templateIds?templateIds=${templateIds}`);
      const salesData = response.data.data;

      Object.keys(salesData).forEach(templateId => {
        sales[templateId] = salesData[templateId];
      });

    } catch (error) {
      console.error('Error fetching NFTs for sale:', error);
    }
    setNftsForSale(sales);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchMintNumbers(imagesRef.current);
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, [fetchMintNumbers]);

  const fetchWalletOwner = async (nftId, templateId) => {
    const dynamodb = new AWS.DynamoDB.DocumentClient();

    if (!nftId || !templateId) {
      console.error(`Invalid nftId or templateId: nftId=${nftId}, templateId=${templateId}`);
      return 'Unknown';
    }

    const params = {
      TableName: 'AFLMint',
      IndexName: 'nftId-index',
      KeyConditionExpression: 'nftId = :nftId',
      ExpressionAttributeValues: {
        ':nftId': nftId
      },
      ProjectionExpression: 'walletId'
    };

    try {
      const data = await dynamodb.query(params).promise();
      if (data.Items.length > 0) {
        const walletId = data.Items[0].walletId;
        const raffleParams = {
          TableName: 'RaffleEntries',
          IndexName: 'walletId-discordName-index',
          KeyConditionExpression: 'walletId = :walletId',
          ExpressionAttributeValues: {
            ':walletId': walletId
          },
          ProjectionExpression: 'discordName'
        };

        const raffleData = await dynamodb.query(raffleParams).promise();
        if (raffleData.Items.length > 0) {
          return raffleData.Items[0].discordName;
        } else {
          return walletId;
        }
      } else {
        return 'Unknown';
      }
    } catch (err) {
      console.error(`Error fetching wallet owner for nftId ${nftId} and templateId ${templateId}:`, err);
      return 'Unknown';
    }
  };

  const handleHover = async (templateId, mintNumber) => {
    const mintItem = imagesRef.current.find(image => image.templateId === templateId)?.mintNumbers?.find(item => item.mintNumber === mintNumber);
    if (mintItem && !walletOwners[`${templateId}-${mintNumber}`]) {
      setLoading(prevState => ({ ...prevState, [`${templateId}-${mintNumber}`]: true }));
      const ownerInfo = await fetchWalletOwner(mintItem.nftId, templateId);
      if (ownerInfo) {
        setWalletOwners(prevState => ({
          ...prevState,
          [`${templateId}-${mintNumber}`]: ownerInfo
        }));
      } else {
        setWalletOwners(prevState => ({
          ...prevState,
          [`${templateId}-${mintNumber}`]: 'Unknown'
        }));
      }
      setLoading(prevState => ({ ...prevState, [`${templateId}-${mintNumber}`]: false }));
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Wallet ID copied to clipboard!');
    }).catch(err => {
      console.error('Error copying text: ', err);
    });
  };

  const sortedMintNumbers = (mintNumbers) => {
    if (!mintNumbers) return [];
    return mintNumbers.sort((a, b) => {
      if (sortOrder === 'asc') {
        return parseInt(a.mintNumber, 10) - parseInt(b.mintNumber, 10);
      } else if (sortOrder === 'desc') {
        return parseInt(b.mintNumber, 10) - parseInt(a.mintNumber, 10);
      } else if (sortOrder === 'mintTimeAsc') {
        return new Date(a.eventDate) - new Date(b.eventDate);
      } else if (sortOrder === 'mintTimeDesc') {
        return new Date(b.eventDate) - new Date(a.eventDate);
      }
      return 0;
    });
  };

  const filteredMintNumbers = (mintNumbers, filter) => {
    if (!mintNumbers) return [];
    if (!filter) return mintNumbers;

    return mintNumbers.filter(mintItem => mintItem.mintNumber.toString() === filter.trim());
  };

  const handleImageError = (e, templateId) => {
    if (staticImages[templateId]) {
      e.target.src = staticImages[templateId];
    } else {
      console.error('Image failed to load and no static image available');
    }
  };

  const scrollLeft = () => {
    scrollRef.current.scrollBy({
      left: -300,
      behavior: 'smooth'
    });
  };

  const scrollRight = () => {
    scrollRef.current.scrollBy({
      left: 300,
      behavior: 'smooth'
    });
  };

  const isMintNumberForSale = (templateId, mintNumber) => {
    const nfts = nftsForSale[templateId] || [];
    return Array.isArray(nfts) && nfts.some(nft => nft.serial.toString() === mintNumber);
  };

  const getNftSalePrice = (templateId, mintNumber) => {
    const nfts = nftsForSale[templateId] || [];
    const nft = nfts.find(nft => nft.serial.toString() === mintNumber);
    if (nft) {
      const priceInAud = (nft.price * usdToAudRate).toFixed(2);
      return priceInAud;
    }
    return null;
  };

  const handlePinSubmit = () => {
    const correctPin = process.env.REACT_APP_PIN;
    if (pin === correctPin) {
      setIsPinCorrect(true);
      sessionStorage.setItem('pinValidated', 'true');
    } else {
      alert('Incorrect PIN');
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handlePinSubmit();
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>AFLMint Moments HUB </h1>
        <button 
          className="home-button"
          onClick={() => window.location.href = '/'}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home">
            <path d="M3 9.5L12 4l9 5.5v9.5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
            <polyline points="9 22 9 12 15 12 15 22"></polyline>
          </svg>
        </button>
      </header>
      <div className="content">
        {!isPinCorrect ? (
          <div className="pin-container">
            <input
              type="password"
              placeholder="Enter PIN"
              value={pin}
              onChange={(e) => setPin(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <button onClick={handlePinSubmit}>Submit</button>
          </div>
        ) : (
          <div className="dropdowns-container">
            <button className="scroll-button left" onClick={scrollLeft}>◀</button>
            <div className="dropdowns">
              <select
                className="collection-name-select"
                onChange={(e) => {
                  setSelectedCollectionName(e.target.value);
                  setSelectedCollectionDate('');
                }}
                value={selectedCollectionName}
              >
                <option value="">Select a Collection</option>
                {collectionNames.map((name, index) => (
                  <option key={index} value={name}>{name}</option>
                ))}
              </select>
              <select
                className="collection-date-select"
                onChange={(e) => setSelectedCollectionDate(e.target.value)}
                value={selectedCollectionDate}
                disabled={!selectedCollectionName}
              >
                <option value="">Select a Collection Date</option>
                {collectionDates.map((date, index) => (
                  <option key={index} value={date}>{date}</option>
                ))}
              </select>
              <select
                className="team-select"
                onChange={(e) => setSelectedClub(e.target.value)}
                value={selectedClub}
              >
                <option value="">Select a Club</option>
                {clubs.map((club, index) => (
                  <option key={index} value={club}>{club}</option>
                ))}
              </select>
              <select
                className="tier-select"
                onChange={(e) => setSelectedTier(e.target.value)}
                value={selectedTier}
              >
                <option value="">Select a Tier</option>
                {tiers.map((tier, index) => (
                  <option key={index} value={tier}>{tier}</option>
                ))}
              </select>
              <select
                className="player-select"
                onChange={(e) => setSelectedPlayer(e.target.value)}
                value={selectedPlayer}
              >
                <option value="">Select a Player</option>
                {players.map((player, index) => (
                  <option key={index} value={player}>{player}</option>
                ))}
              </select>
              <select
                className="sort-select"
                onChange={(e) => setSortOrder(e.target.value)}
                value={sortOrder}
              >
                <option value="asc">Sort by Mint Number (Asc)</option>
                <option value="desc">Sort by Mint Number (Desc)</option>
                <option value="mintTimeAsc">Sort by Mint Time (Asc)</option>
                <option value="mintTimeDesc">Sort by Mint Time (Desc)</option>
              </select>
              <input
                type="text"
                className="mint-number-filter"
                placeholder="Filter by Mint Number"
                value={mintNumberFilter}
                onChange={(e) => setMintNumberFilter(e.target.value)}
              />

              <div className="jumper-number-filter">
                <input
                  type="checkbox"
                  id="jumperNumber"
                  checked={filterByJumperNumber}
                  onChange={(e) => setFilterByJumperNumber(e.target.checked)}
                />
                <label htmlFor="jumperNumber">Player Jumper Number</label>
              </div>
            </div>
            <button className="scroll-button right" onClick={scrollRight}>▶</button>
          </div>
        )}
        <div className="horizontal-scroll" ref={scrollRef}>
          <div className={`image-gallery ${images.length === 1 ? 'single-image' : ''}`}>
            {images.map((image, index) => (
              <div key={index} className="image-column">
                {image.thumbnail ? (
                  <img
                    src={image.thumbnail}
                    alt={`Thumbnail for ${image.templateId}`}
                    onError={(e) => handleImageError(e, image.templateId)}
                  />
                ) : (
                  <video
                    src={image.video}
                    controls
                    className="video-thumbnail"
                  />
                )}
                <div className="image-info">
                  <p className={`info-line ${image.mintNumbers?.some(mintItem => mintItem.mintNumber === image.playersJumperNumber) ? 'highlighted' : ''}`}>
                    Jumper #{image.playersJumperNumber}
                    <img src="https://www.aflmint.com.au/assets/Badges/GuernseySerial.svg" alt="Guernsey Serial" style={{ width: '18px', height: '18px', marginLeft: '5px' }} />
                  </p>
                  {image.specialSerials?.map(serial => (
                    <p key={serial} className={`info-line ${image.mintNumbers?.some(mintItem => mintItem.mintNumber === serial) ? 'highlighted' : ''}`}>
                      Special #{serial}
                    </p>
                  ))}
                </div>
                <div className="mint-numbers">
                  <h3>{`${image.mintNumbers.length}/${image.specialSerials[1]}`}</h3>
                  {filteredMintNumbers(image.mintNumbers, mintNumberFilter).length > 0 ? (
                    <ul>
                      {sortedMintNumbers(filteredMintNumbers(image.mintNumbers, mintNumberFilter)).map((mintItem, i) => (
                        <li
                          key={i}
                          className={`mint-number ${mintItem.mintNumber === image.playersJumperNumber || image.specialSerials?.includes(mintItem.mintNumber) ? 'highlight' : ''}`}
                          onMouseEnter={() => handleHover(image.templateId, mintItem.mintNumber)}
                        >
                          {isMintNumberForSale(image.templateId, mintItem.mintNumber) && (
                            <a href={`https://www.aflmint.com.au/marketplace/buy/${image.templateId}`} target="_blank" rel="noopener noreferrer">
                              <img
                                src="/images/green-dollar-sign.png"
                                alt="For Sale"
                                style={{ width: '18px', height: '18px', marginRight: '10px'}}
                              />
                            </a>
                          )}
                          <span>{mintItem.mintNumber}</span>
                          {loading[`${image.templateId}-${mintItem.mintNumber}`] ? (
                            <span className="owner-info">Loading...</span>
                          ) : walletOwners[`${image.templateId}-${mintItem.mintNumber}`] ? (
                            <span className="owner-info yellow-text">
                              Owned by {walletOwners[`${image.templateId}-${mintItem.mintNumber}`]}
                              <button className="copy-button" onClick={() => copyToClipboard(walletOwners[`${image.templateId}-${mintItem.mintNumber}`])}>
                                Copy
                              </button>
                              {isMintNumberForSale(image.templateId, mintItem.mintNumber) && (
                                <p className="market-price">
                                  MP: ${getNftSalePrice(image.templateId, mintItem.mintNumber)} AUD
                                </p>
                              )}
                            </span>
                          ) : (
                            <span className="owner-info yellow-text">
                              Wallet ID: {mintItem.nftId}
                              <button className="copy-button" onClick={() => copyToClipboard(mintItem.nftId)}>
                                Copy
                              </button>
                              {isMintNumberForSale(image.templateId, mintItem.mintNumber) && (
                                <p className="market-price">
                                  MP: ${getNftSalePrice(image.templateId, mintItem.mintNumber)} AUD
                                </p>
                              )}
                            </span>
                          )}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No mint numbers match the filter criteria.</p>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MomentInfo;
