import React, { useState, useEffect, useRef } from 'react';
import './App.css'; // Ensure this file contains the updated CSS
import AWS from 'aws-sdk';

function App() {
  const initialImages = [
    {
      templateId: 28321,
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/rookies-2024/Draft-Pick-01:-Harley-Reid/MINT_ROOKIETOKEN_PICK_01",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '9',
      specialSerials: ['1', '365']
    },
    {
      templateId: 28322,
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/rookies-2024/Draft-Pick-02:-Colby-McKercher/MINT_ROOKIETOKEN_PICK_02",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '10',
      specialSerials: ['1', '365']
    },
    {
      templateId: 28323,
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/rookies-2024/Draft-Pick-03:-Jed-Walter/MINT_ROOKIETOKEN_PICK_03",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '17',
      specialSerials: ['1', '365']
    },
    {
      templateId: 28324,
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/rookies-2024/Draft-Pick-04:-Zane-Duursma/MINT_ROOKIETOKEN_PICK_04",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '7',
      specialSerials: ['1', '365']
    },
    {
      templateId: 28325,
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/rookies-2024/Draft-Pick-05:-Nick-Watson/MINT_ROOKIETOKEN_PICK_05",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '34',
      specialSerials: ['1', '365']
    },
    {
      templateId: 28326,
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/rookies-2024/Draft-Pick-06:-Ryley-Sanders/MINT_ROOKIETOKEN_PICK_06",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '9',
      specialSerials: ['1', '365']
    },
    {
      templateId: 28327,
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/rookies-2024/Draft-Pick-07:-Caleb-Windsor/MINT_ROOKIETOKEN_PICK_07",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '6',
      specialSerials: ['1', '365']
    },
    {
      templateId: 28328,
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/rookies-2024/Draft-Pick-08:-Daniel-Curtin/MINT_ROOKIETOKEN_PICK_08",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '6',
      specialSerials: ['1', '365']
    },
    {
      templateId: 28329,
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/rookies-2024/Draft-Pick-09:-Ethan-Read/MINT_ROOKIETOKEN_PICK_09",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '20',
      specialSerials: ['1', '365']
    },
    {
      templateId: 28330,
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/rookies-2024/Draft-Pick-10:-Nate-Caddy/MINT_ROOKIETOKEN_PICK_10",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '30',
      specialSerials: ['1', '365']
    },
    {
      templateId: 28331,
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/rookies-2024/Draft-Pick-11:-Connor-O'Sullivan/MINT_ROOKIETOKEN_PICK_11",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '14',
      specialSerials: ['1', '365']
    },
    {
      templateId: 28332,
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/rookies-2024/Draft-Pick-12:-Phoenix-Gothard/MINT_ROOKIETOKEN_PICK_12",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '29',
      specialSerials: ['1', '365']
    },
    {
      templateId: 28333,
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/rookies-2024/Draft-Pick-13:-Koltyn-Tholstrup/MINT_ROOKIETOKEN_PICK_13",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '39',
      specialSerials: ['1', '365']
    },
    {
      templateId: 28334,
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/rookies-2024/Draft-Pick-14:-Jake-Rogers/MINT_ROOKIETOKEN_PICK_14",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '29',
      specialSerials: ['1', '365']
    },
    {
      templateId: 28335,
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/rookies-2024/Draft-Pick-15:-Jordan-Croft/MINT_ROOKIETOKEN_PICK_15",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '16',
      specialSerials: ['1', '365']
    },
    {
      templateId: 28336,
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/rookies-2024/Draft-Pick-16:-Will-Green/MINT_ROOKIETOKEN_PICK_16",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '45',
      specialSerials: ['1', '365']
    },
    {
      templateId: 28337,
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/rookies-2024/Draft-Pick-17:-James-Leake/MINT_ROOKIETOKEN_PICK_17",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '30',
      specialSerials: ['1', '365']
    },
    {
      templateId: 28338,
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/rookies-2024/Draft-Pick-18:-Darcy-Wilson/MINT_ROOKIETOKEN_PICK_18",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '22',
      specialSerials: ['1', '365']
    },
    {
      templateId: 28339,
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/rookies-2024/Draft-Pick-19:-Will-McCabe/MINT_ROOKIETOKEN_PICK_19",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '27',
      specialSerials: ['1', '365']
    },
    {
      templateId: 28340,
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/rookies-2024/Draft-Pick-20:-Taylor-Goad/MINT_ROOKIETOKEN_PICK_20",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '22',
      specialSerials: ['1', '365']
    }
  ];

  const [images, setImages] = useState(initialImages);
  const imagesRef = useRef(initialImages);

  useEffect(() => {
    // Configure AWS SDK
    AWS.config.update({
      region: process.env.REACT_APP_AWS_REGION, // Use environment variable for region
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID, // Use environment variable for access key ID
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY, // Use environment variable for secret access key
    });

    const dynamodb = new AWS.DynamoDB.DocumentClient();

    const fetchData = async () => {
      const newImages = [...imagesRef.current];
      let isUpdated = false;

      for (let i = 0; i < newImages.length; i++) {
        const image = newImages[i];
        const params = {
          TableName: 'AFLMint',
          IndexName: 'templateId-index',
          KeyConditionExpression: 'templateId = :tid',
          ExpressionAttributeValues: {
            ':tid': image.templateId // Number
          },
          ProjectionExpression: 'mintNumber, eventDate'
        };

        try {
          const data = await dynamodb.query(params).promise();
          const items = data.Items.map(item => ({
            mintNumber: item.mintNumber,
            eventDate: item.eventDate
          }));

          items.sort((a, b) => new Date(b.eventDate) - new Date(a.eventDate));
          const mintNumbers = items.map(item => item.mintNumber);
          const totalMinted = mintNumbers.length;
          const maxSerial = image.specialSerials[1] || '?'; // Handle missing maxSerial
          const serialCount = `${totalMinted}/${maxSerial}`;

          if (mintNumbers.toString() !== image.mintNumbers.toString() || serialCount !== image.serialCount) {
            newImages[i] = {
              ...newImages[i],
              mintNumbers,
              serialCount
            };
            isUpdated = true;
          }
        } catch (err) {
          console.error(`Unable to query for templateId ${image.templateId}. Error:`, JSON.stringify(err, null, 2));
        }
      }

      if (isUpdated) {
        imagesRef.current = newImages;
        setImages(newImages);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 300000);
    return () => clearInterval(intervalId);
  }, []);

  const getThumbnailUrl = (baseUrl) => `${baseUrl}_FRONT.jpg`;

  return (
    <div className="App">
      <header className="App-header">
        <div className="logo-container">
          <img src="/images/aflmint-logo.png" alt="AFL Mint Drops Logo" className="logo" />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h1 className="App-title" style={{ marginRight: '5px' }}>AFLMint Rookies 2024</h1>
          <img
            src="https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/rookies-2024/ROOKIETOKEN_10PACK_HQ.png"
            alt="2024 Pack"
            style={{ height: 'auto', width: 'auto', maxHeight: '100px', maxWidth: '100px' }}
          />
        </div>
      </header>
      <div className="image-gallery">
        {images.map((image, index) => (
          <div key={index} className="image-column">
            <div>
              <img
                src={getThumbnailUrl(image.baseUrl)}
                alt={`Thumbnail ${index + 1}`}
              />
              <div className="image-info">
                <p className={`info-line ${image.mintNumbers.includes(image.highlightedJumper) ? 'highlighted' : ''}`}>
                  Jumper #{image.highlightedJumper}
                  <img src="https://www.aflmint.com.au/assets/Badges/GuernseySerial.svg" alt="Guernsey Serial" style={{ width: '18px', height: '18px', marginLeft: '5px' }} />
                </p>
                {image.specialSerials.map(serial => (
                  <p key={serial} className={`info-line ${image.mintNumbers.includes(serial) ? 'highlighted' : ''}`}>
                    Special #{serial}
                  </p>
                ))}
              </div>
            </div>
            <div className="mint-numbers">
              <h2>{image.serialCount}</h2>
              {image.mintNumbers.map((mintNumber, idx) => (
                <div key={idx} className={`mint-number ${mintNumber === image.highlightedJumper || image.specialSerials.includes(mintNumber) ? 'highlight' : ''}`}>
                  <span style={{ color: mintNumber === image.highlightedJumper || image.specialSerials.includes(mintNumber) ? 'blue' : 'inherit', fontWeight: mintNumber === image.highlightedJumper || image.specialSerials.includes(mintNumber) ? 'bold' : 'normal' }}>{mintNumber}</span>
                  {mintNumber === image.highlightedJumper && (
                    <img src="https://www.aflmint.com.au/assets/Badges/GuernseySerial.svg" alt="Guernsey Serial" style={{ width: '18px', height: '18px', marginLeft: '5px' }} />
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;
