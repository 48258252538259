// src/Clock.js
import React, { useState, useEffect } from 'react';

const Clock = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const timeOptions = {
    timeZone: 'Australia/Brisbane',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  };

  const dateOptions = {
    timeZone: 'Australia/Brisbane',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  return (
    <div className="clock">
      <h3>Current Time (EST Brisbane)</h3>
      <p>{time.toLocaleDateString('en-AU', dateOptions)}</p>
      <p>{time.toLocaleTimeString('en-AU', timeOptions)}</p>
    </div>
  );
};

export default Clock;
