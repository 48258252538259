import React, { useState, useEffect, useCallback } from 'react';
import { Wheel } from 'react-custom-roulette';
import AWS from 'aws-sdk';
import Clock from './Clock';
import './App.css';

// Initialize AWS SDK
AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});
const dynamodb = new AWS.DynamoDB.DocumentClient();

const ADMIN_PASSWORD = process.env.REACT_APP_ADMIN_PASSWORD; // Read the environment variable

const placeholderData = [
  { option: 'ENTER NOW' },
  { option: 'ENTER NOW' },
  { option: 'ENTER NOW' },
  { option: 'ENTER NOW' },
  { option: 'ENTER NOW' }
];

function AFLMintRaffles() {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [data, setData] = useState(placeholderData); // Initial placeholders
  const [discordName, setDiscordName] = useState('');
  const [walletId, setWalletId] = useState('');
  const [transaction, setTransaction] = useState('');
  const [templateId, setTemplateId] = useState('');
  const [prizeInfo, setPrizeInfo] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [prizeLocked, setPrizeLocked] = useState(false);
  const [adminPassword, setAdminPassword] = useState('');
  const [drawId, setDrawId] = useState('008'); // Example draw ID
  const [winner, setWinner] = useState(null); // State to hold the winner's name
  const [quantity, setQuantity] = useState(1); // State to hold ticket quantity

  const fetchRaffleEntries = useCallback(async () => {
    try {
      const params = {
        TableName: 'RaffleEntries',
        FilterExpression: 'drawId = :drawId',
        ExpressionAttributeValues: { ':drawId': drawId }
      };
      const data = await dynamodb.scan(params).promise();
      const entries = data.Items.map(item => ({ option: item.discordName }));

      // Merge entries with placeholder data
      const combinedData = entries.concat(
        placeholderData.slice(0, Math.max(0, placeholderData.length - entries.length))
      );

      setData(combinedData);
    } catch (error) {
      console.error('Error fetching raffle entries:', error);
    }
  }, [drawId]);

  useEffect(() => {
    fetchLockedPrize();
    fetchRaffleEntries();

    const interval = setInterval(() => {
      fetchRaffleEntries();  // Re-fetch the entries every minute
    }, 60000);

    return () => clearInterval(interval);  // Cleanup to avoid memory leaks and unnecessary work
  }, [fetchRaffleEntries]);

  const fetchLockedPrize = async () => {
    try {
      const params = {
        TableName: 'LockedPrizes',
        Key: { prizeId: 'current' }
      };
      const data = await dynamodb.get(params).promise();
      if (data.Item) {
        setPrizeInfo(data.Item.prizeInfo);
        setPrizeLocked(true);
      }
    } catch (error) {
      console.error('Error fetching locked prize:', error);
    }
  };

  const handleSpinClick = () => {
    const newPrizeNumber = Math.floor(Math.random() * data.length);
    setPrizeNumber(newPrizeNumber);
    setMustSpin(true);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
  
    const batchWriteParams = {
      RequestItems: {
        RaffleEntries: []
      }
    };
  
    for (let i = 0; i < quantity; i++) {
      const entryId = `${drawId}_${discordName}_${walletId}_${transaction}_${i}`; // Create a unique entry ID
  
      batchWriteParams.RequestItems.RaffleEntries.push({
        PutRequest: {
          Item: {
            entryId: entryId,
            drawId: drawId,
            discordName: discordName,
            walletId: walletId,
            transaction: transaction
          }
        }
      });
    }
  
    try {
      await dynamodb.batchWrite(batchWriteParams).promise();
      setDiscordName('');
      setWalletId('');
      setTransaction('');
      setQuantity(1); // Reset quantity to default
      fetchRaffleEntries(); // Fetch entries again after adding a new one
    } catch (err) {
      console.error('Error storing entry:', err);
    }
  };

  const handleTemplateLookup = async (e) => {
    e.preventDefault();

    if (!isAdmin && prizeLocked) {
      alert('Only admins can look up the prize.');
      return;
    }

    try {
      // Fetch data from AFLMoments table using GSI templateId-eventDate-index
      const momentsParams = {
        TableName: 'AFLMoments',
        IndexName: 'templateId-eventDate-index',
        KeyConditionExpression: 'templateId = :templateId',
        ExpressionAttributeValues: { ':templateId': parseInt(templateId, 10) } // Ensure templateId is a number
      };
      const momentsData = await dynamodb.query(momentsParams).promise();
      if (momentsData.Items.length === 0) {
        setPrizeInfo(null);
        return;
      }

      const prizeInfo = {
        thumbnail: momentsData.Items[0].thumbnail,
        mintNumber: momentsData.Items[0].mintNumber, // Ensure mintNumber is included in projection
        templateId,
      };

      setPrizeInfo(prizeInfo);
    } catch (error) {
      console.error('Error looking up Template:', error);
      setPrizeInfo(null);
    }
  };

  const handleLockPrize = async () => {
    if (!prizeInfo) {
      alert('No prize information to lock.');
      return;
    }

    try {
      const params = {
        TableName: 'LockedPrizes',
        Item: {
          prizeId: 'current',
          prizeInfo,
        }
      };
      await dynamodb.put(params).promise();
      setPrizeLocked(true);
    } catch (error) {
      console.error('Error locking prize:', error);
    }
  };

  const handleAdminLogin = (e) => {
    e.preventDefault();
    if (adminPassword === ADMIN_PASSWORD) {
      setIsAdmin(true);
      setAdminPassword('');
    } else {
      alert('Incorrect admin password.');
    }
  };

  const handleUpdateDrawId = () => {
    const newDrawId = prompt('Enter new Draw ID:');
    if (newDrawId) {
      setDrawId(newDrawId);
      fetchRaffleEntries(); // Fetch entries for the new draw ID
    }
  };

  const handleUnlockPrize = () => {
    setPrizeLocked(false);
    setPrizeInfo(null);
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>AFL Mint Raffles</h1>
        <button 
          className="home-button"
          onClick={() => window.location.href = '/'} // Replace with your home URL if different
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home">
            <path d="M3 9.5L12 4l9 5.5v9.5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
            <polyline points="9 22 9 12 15 12 15 22"></polyline>
          </svg>
        </button>
        <h2 className="subheading">Rogue's Port Adelaide Ball! Tickets 10 FLOW OR USDC : 0x64222cd5d597d23b	  </h2> {/* Subheading example */}
      </header>
      <div className="raffle-container">
        <div className="admin-container">
          {!isAdmin && (
            <form onSubmit={handleAdminLogin}>
              <div>
                <label>
                  Admin Password:
                  <input
                    type="password"
                    value={adminPassword}
                    onChange={(e) => setAdminPassword(e.target.value)}
                    required
                  />
                </label>
              </div>
              <button type="submit">Login as Admin</button>
            </form>
          )}
          {isAdmin && (
            <>
              <button onClick={handleUpdateDrawId}>Update Draw ID</button>
              <button onClick={handleUnlockPrize}>Unlock Prize Lookup</button>
            </>
          )}
        </div>
        <div className="form-container">
          <form onSubmit={handleFormSubmit}>
            <div>
              <label>
                Discord Name:
                <input
                  type="text"
                  value={discordName}
                  onChange={(e) => setDiscordName(e.target.value)}
                  required
                />
              </label>
            </div>
            <div>
              <label>
                Wallet ID:
                <input
                  type="text"
                  value={walletId}
                  onChange={(e) => setWalletId(e.target.value)}
                  required
                />
              </label>
            </div>
            <div>
              <label>
                Transaction:
                <input
                  type="text"
                  value={transaction}
                  onChange={(e) => setTransaction(e.target.value)}
                  required
                />
              </label>
            </div>
            <div>
              <label>
                Ticket Quantity:
                <select
                  value={quantity}
                  onChange={(e) => setQuantity(parseInt(e.target.value, 10))}
                  required
                >
                  {[...Array(10).keys()].map(num => (
                    <option key={num + 1} value={num + 1}>{num + 1}</option>
                  ))}
                </select>
              </label>
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
        <div className="wheel-container">
        <Wheel
          mustStartSpinning={mustSpin}
          prizeNumber={prizeNumber}
          data={data}
          backgroundColors={['#3e3e3e', '#df3428']}
          textColors={['#ffffff']}
          outerBorderColor="#cccccc"
          outerBorderWidth={10}
          innerRadius={5}
          radiusLineColor="#cccccc"
          radiusLineWidth={1}
          fontSize={12} // Reduced font size to fit more entries
          textDistance={50} // Adjust text distance to fit more entries
          onStopSpinning={() => {
            setMustSpin(false);
            setWinner(data[prizeNumber].option); // Set the winner's name
            setTimeout(() => {
              setWinner(null); // Clear the winner's name after a delay
            }, 5000); // Display the winner's name for 5 seconds
          }}
        />
          <button 
            onClick={handleSpinClick} 
            className="spin-button"
            disabled={!isAdmin || data.length === 0} // Disable if not admin or no data
          >
            Spin the Wheel!
          </button>
          {winner && (
            <div className="winner-message">
              <h2>{winner} is the winner!</h2>
            </div>
          )}
        </div>
        <div className="clock-container">
          <Clock />
          <form onSubmit={handleTemplateLookup}>
            <div>
              <label>
                <input
                  type="number" // Ensure the input is a number
                  value={templateId}
                  onChange={(e) => setTemplateId(e.target.value)}
                  required
                  disabled={prizeLocked && !isAdmin} // Disable input if prize is locked and not an admin
                />
              </label>
            </div>
            <button type="submit" disabled={prizeLocked && !isAdmin}>Look Up Prize</button>
          </form>
          {prizeInfo && (
            <div className="prize-info">
              <h3>Prize Information</h3>
              <p>Draw: {drawId} GO BOMBERS!</p>
              <img src={prizeInfo.thumbnail} alt="Prize Thumbnail" className="prize-thumbnail" />
              {!prizeLocked && isAdmin && (
                <button onClick={handleLockPrize}>Lock Prize</button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AFLMintRaffles;
