// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './LandingPage';
import Starters2024 from './Starters2024';
import Rookies2024 from './Rookies2024';
import MintMoments2024Rounds6to10 from './MintMoments2024Rounds6to10'; // Import your MintMoments2024Rounds6to10 component
import MomentInfo from './MomentInfo'; 
import AFLMintRaffles from './AFLMintRaffles';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/starters2024" element={<Starters2024 />} />
        <Route path="/MintMoments2024Rounds6to10" element={<MintMoments2024Rounds6to10 />} />
        <Route path="/Rookies2024" element={<Rookies2024 />} />
        <Route path="/MomentInfo" element={<MomentInfo />} />
        <Route path="/AFLMintRaffles" element={<AFLMintRaffles />} />
        {/* Add more routes as needed */}
      </Routes>
    </Router>
  );
}

export default App;
