import React from 'react';
import { Link } from 'react-router-dom';
import './LandingPage.css'; // Import the CSS file for styling

const LandingPage = () => {
  return (
    <div className="landing-container">
      <header className="header">
        <div className="logo-container">
          <img src="/images/aflmint-logo.png" alt="AFL Mint Drops Logo" className="logo" />
        </div>
        <nav>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/services">Services</Link></li>
            <li><Link to="/contact">Contact</Link></li>
          </ul>
        </nav>
      </header>

      <main className="main-content">
        <section className="hero-section">
          {/* Hero content goes here */}
        </section>

        <section className="drops-section">
          <div className="drops-grid">
            <Link to="/MomentInfo" className="drop-card">
              <div className="drop-image" style={{ backgroundImage: 'url(/images/aflmint-logo.png)' }}></div>
              <div className="drop-info">
                <h3>AFLMint Hub</h3>
                <p>Hunt down your special serials and connect with your favorite Discord members for a perfect trade!</p>
              </div>
            </Link>
            <Link to="/AFLMintRaffles" className="drop-card">
              <div className="drop-image" style={{ backgroundImage: 'url(/images/raffle.png)' }}></div>
              <div className="drop-info">
                <h3>AFLMint Raffles</h3>
                <p>Experience the excitement with AFLMint's weekly community raffle, celebrating all your favorite moments!</p>
              </div>
            </Link>
          </div>
        </section>
      </main>

      <footer className="footer">
        <p>&copy; 2024 AFL Mint Drops. All rights reserved.</p>
        <div className="social-links">
          <a href="https://twitter.com/AFLMint" target="_blank" rel="noopener noreferrer">Twitter</a>
          <a href="https://facebook.com/AFLMint" target="_blank" rel="noopener noreferrer">Facebook</a>
          <a href="https://instagram.com/AFLMint" target="_blank" rel="noopener noreferrer">Instagram</a>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
