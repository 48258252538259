import React, { useState, useEffect, useRef } from 'react';
import './App.css'; // Ensure this file contains the updated CSS
import AWS from 'aws-sdk';

function App() {
  const initialImages = [
    {
      templateId: '28977',
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/starter-pack-2024/McCluggage-shakes-off-Saint-for-sweetest-of-finishes/02_MINT_MMS3_MCCLUGGAGE_GOAL",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '6',
      specialSerials: ['1', '1200']
    },
    {
      templateId: '28978',
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/starter-pack-2024/Lovely-long-bomb-from-outside-50-courtesy-of-Kennedy/03_MINT_MMS3_KENNEDY_GOAL",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '7',
      specialSerials: ['1', '1200']
    },
    {
      templateId: '28979',
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/starter-pack-2024/Markov's-first-goal-as-a-Magpie-shines-bright-on-old-club's-turf/04_MINT_MMS3_MARKOV_GOAL",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '37',
      specialSerials: ['1', '1200']
    },
    {
      templateId: '28980',
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/starter-pack-2024/O'Meara-sends-it-home-on-game-150/06_MINT_MMS3_OMEARA_GOAL",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '2',
      specialSerials: ['1', '1200']
    },
    {
      templateId: '28981',
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/starter-pack-2024/Clever-Keays-kicks-a-quick-six-right-in-front/01_MINT_MMS3_KEAYS_GOAL",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '2',
      specialSerials: ['1', '150']
    },
    {
      templateId: '28982',
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/starter-pack-2024/Big-Blicavs-is-on-the-mark-with-this-super-speccy/07_MINT_MMS3_BLICAVS_MARK",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '46',
      specialSerials: ['1', '150']
    },
    {
      templateId: '28983',
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/starter-pack-2024/Parish-boots-snappy-major-after-receiving-the-Wright-handball/05_MINT_MMS3_PARISH_GOAL",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '3',
      specialSerials: ['1', '1200']
    },
    {
      templateId: '28984',
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/starter-pack-2024/Andrew-makes-the-one-handed-mark-look-easy/08_MINT_MMS3_ANDREW_MARK",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '31',
      specialSerials: ['1', '1200']
    },
    {
      templateId: '28985',
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/starter-pack-2024/A-maiden-major-to-remember-for-GIANTS'-Aaron-Cadman/09_MINT_MMS3_CADMAN_GOAL",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '5',
      specialSerials: ['1', '1200']
    },
    {
      templateId: '28986',
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/starter-pack-2024/Marvel-lights-up-with-lethal-long-range-major-from-Amon/10_MINT_MMS3_AMON_GOAL",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '10',
      specialSerials: ['1', '150']
    },
    {
      templateId: '28987',
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/starter-pack-2024/Viney-wows-with-a-classy-spin-into-space-for-the-finisher/11_MINT_MMS3_VINEY_GOAL",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '7',
      specialSerials: ['1', '150']
    },
    {
      templateId: '28988',
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/starter-pack-2024/LDU-launches-a-late-rocket-to-put-Roos-in-reach-of-win/12_MINT_MMS3_DAVIES_UNIACKE_GOAL",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '10',
      specialSerials: ['1', '50']
    },
    {
      templateId: '28989',
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/starter-pack-2024/Rioli-runs-rings-around-Dees-defence-and-delivers/14_MINT_MMS3_RIOLI_GOAL",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '17',
      specialSerials: ['1', '1200']
    },
    {
      templateId: '28990',
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/starter-pack-2024/Lord's-dive-mark-and-beaut-banana-puts-Port-back-in-front/13_MINT_MMS3_LORD_GOAL",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '30',
      specialSerials: ['1', '50']
    },
    {
      templateId: '28991',
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/starter-pack-2024/Defensive-marking-at-it's-best-thanks-to-Barrass/17_MINT_MMS3_BARRASS_MARK",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '37',
      specialSerials: ['1', '1200']
    },
    {
      templateId: '28992',
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/starter-pack-2024/Booming-Lobb-bomb-brings-Dogs-back-in-it-/18_MINT_MMS3_LOBB_GOAL",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '7',
      specialSerials: ['1', '1200']
    },
    {
      templateId: '28993',
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/starter-pack-2024/McLean-cleans-up-with-this-magic-mark/16_MINT_MMS3_MCLEAN_MARK",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '2',
      specialSerials: ['1', '1200']
    },
    {
      templateId: '28994',
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/starter-pack-2024/High-marking-Marshall's-memorable-climb/15_MINT_MMS3_MARSHALL_MARK",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '19',
      specialSerials: ['1', '1200']
    }
  ];

  const [images, setImages] = useState(initialImages);
  const imagesRef = useRef(initialImages);
  
  useEffect(() => {
    // Configure AWS SDK
    AWS.config.update({
      region: process.env.REACT_APP_AWS_REGION, // Use environment variable for region
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID, // Use environment variable for access key ID
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY, // Use environment variable for secret access key
    });

     
    const dynamodb = new AWS.DynamoDB();
  
    const fetchData = async () => {
      const newImages = [...imagesRef.current];
      let isUpdated = false;
  
      for (let i = 0; i < newImages.length; i++) {
        const image = newImages[i];
        const params = {
          TableName: 'AFLMint',
          IndexName: 'templateId-index',
          KeyConditionExpression: 'templateId = :tid',
          ExpressionAttributeValues: {
            ':tid': { N: image.templateId }
          },
          ProjectionExpression: 'mintNumber, eventDate'
        };
  
        try {
          const data = await dynamodb.query(params).promise();
          const items = data.Items.map(item => ({
            mintNumber: item.mintNumber.S,
            eventDate: item.eventDate.S
          }));
  
          items.sort((a, b) => new Date(b.eventDate) - new Date(a.eventDate));
          const mintNumbers = items.map(item => item.mintNumber);
          const totalMinted = mintNumbers.length;
          const maxSerial = image.specialSerials[1]; // Second special serial is the max serial
          const serialCount = `${totalMinted}/${maxSerial}`;
  
          if (mintNumbers.toString() !== image.mintNumbers.toString() || serialCount !== image.serialCount) {
            newImages[i] = {
              ...newImages[i],
              mintNumbers,
              serialCount
            };
            isUpdated = true;
          }
        } catch (err) {
          console.error('Unable to query. Error:', JSON.stringify(err, null, 2));
        }
      }
  
      if (isUpdated) {
        imagesRef.current = newImages;
        setImages(newImages);
      }
    };
  
    fetchData();
    const intervalId = setInterval(fetchData, 300000);
    return () => clearInterval(intervalId);
  }, []);

  const handleContentChange = (index, content) => {
    setImages(prevImages => {
      const newImages = [...prevImages];
      newImages[index].currentContent = content;
      return newImages;
    });
  };

  const handleFullscreen = async (event) => {
    const videoElement = event.target;

    try {
      if (videoElement.requestFullscreen) {
        await videoElement.requestFullscreen();
      } else if (videoElement.webkitRequestFullscreen) {
        await videoElement.webkitRequestFullscreen();
      } else if (videoElement.msRequestFullscreen) {
        await videoElement.msRequestFullscreen();
      }
    } catch (error) {
      console.error('Error attempting to enter fullscreen mode:', error);
    }
  };

  const getThumbnailUrl = (baseUrl) => `${baseUrl}_FRONT.jpg`;
  const getContentUrl = (baseUrl, suffix) => `${baseUrl}_${suffix}.jpg`;
  const getVideoUrl = (baseUrl) => `${baseUrl}.mp4`;

  return (
    <div className="App">
      <header className="App-header">
      <div className="logo-container">
          <img src="/images/aflmint-logo.png" alt="AFL Mint Drops Logo" className="logo"  /> 
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h1 className="App-title" style={{ marginRight: '5px' }}>AFLMint Starters 2024</h1>
          <img 
            src="https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/starter-pack-2024/2024_PACK_HQ.png" 
            alt="2024 Pack" 
            style={{ height: 'auto', width: 'auto', maxHeight: '100px', maxWidth: '100px' }}  
          />
        </div>
      </header>
      <div className="image-gallery">
        {images.map((image, index) => (
          <div key={index} className="image-column">
            {image.currentContent === 'thumbnail' && (
              <div>
                <img
                  src={getThumbnailUrl(image.baseUrl)}
                  onMouseEnter={() => handleContentChange(index, `image${index + 1}_1`)}
                  alt={`Thumbnail ${index + 1}`}
                />
                <div className="image-info">
                  <p className={`info-line ${image.mintNumbers.includes(image.highlightedJumper) ? 'highlighted' : ''}`}>
                    Jumper #{image.highlightedJumper}
                    <img src="https://www.aflmint.com.au/assets/Badges/GuernseySerial.svg" alt="Guernsey Serial" style={{ width: '18px', height: '18px', marginLeft: '5px' }} />
                  </p>
                  {image.specialSerials.map(serial => (
                    <p key={serial} className={`info-line ${image.mintNumbers.includes(serial) ? 'highlighted' : ''}`}>
                      Special #{serial}
                    </p>
                  ))}
                </div>
              </div>
            )}
            {image.currentContent === `image${index + 1}_1` && (
              <div>
                <img
                  src={getContentUrl(image.baseUrl, 'BACK1')}
                  alt=""
                  onMouseLeave={() => handleContentChange(index, 'thumbnail')}
                  onClick={() => handleContentChange(index, `image${index + 1}_2`)}
                />
              </div>
            )}
            {image.currentContent === `image${index + 1}_2` && (
              <div>
                <img
                  src={getContentUrl(image.baseUrl, 'BACK2')}
                  alt=""
                  onMouseLeave={() => handleContentChange(index, 'thumbnail')}
                  onClick={() => handleContentChange(index, `video${index + 1}`)}
                />
              </div>
            )}
            {image.currentContent === `video${index + 1}` && (
              <video
                controls
                src={getVideoUrl(image.baseUrl)}
                style={{ outline: 'none' }}
                onMouseLeave={() => handleContentChange(index, 'thumbnail')}
                onClick={handleFullscreen}
              />
            )}
            <div className="mint-numbers">
              <h2>{image.serialCount}</h2>
              {image.mintNumbers.map((mintNumber, idx) => (
                <div key={idx} className={`mint-number ${mintNumber === image.highlightedJumper || image.specialSerials.includes(mintNumber) ? 'highlight' : ''}`}>
                  <span style={{ color: mintNumber === image.highlightedJumper || image.specialSerials.includes(mintNumber) ? 'blue' : 'inherit', fontWeight: mintNumber === image.highlightedJumper || image.specialSerials.includes(mintNumber) ? 'bold' : 'normal' }}>{mintNumber}</span>
                  {mintNumber === image.highlightedJumper && (
                    <img src="https://www.aflmint.com.au/assets/Badges/GuernseySerial.svg" alt="Guernsey Serial" style={{ width: '18px', height: '18px', marginLeft: '5px' }} />
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;