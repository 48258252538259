import React, { useState, useEffect, useRef } from 'react';
import './App.css'; // Import your CSS file here
import AWS from 'aws-sdk';



function MintMoments2024Rounds6to10() {
  const initialImages = [
    {
      templateId: '30689',
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/mint-moments/Grundy's-glee-on-show-in-milestone-match/03_MINT_MMS3_RD06_GRUNDY_GOAL",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '4',
      specialSerials: ['1', '70']
    },
    {
      templateId: '30690',
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/mint-moments/Martin's-mega-missile-lifts-the-roof-/01_MINT_MMS3_RD06_MARTIN_GOAL",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '37',
      specialSerials: ['1', '190']
    },
    {
      templateId: '30691',
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/mint-moments/Williams-flies-like-an-Eagle-/02_MINT_MMS3_RD06_WILLIAMS_MARK",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '32',
      specialSerials: ['1', '190']
    },
    {
      templateId: '30692',
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/mint-moments/Debutant-Darcy-dazzles-with-maiden-major/04_MINT_MMS3_R07_JONES_GOAL",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '2',
      specialSerials: ['1', '190']
    },
    {
      templateId: '30693',
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/mint-moments/Elliott's-extraordinary-speccy-brings-house-down/06_MINT_MMS3_R07_ELLIOT_MARK",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '5',
      specialSerials: ['1', '33']
    },
    {
      templateId: '30694',
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/mint-moments/Morris'-magic-debut-moment-with-quirky-twist/10_MINT_MMS3_R08_MORRIS_GOAL",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '13',
      specialSerials: ['1', '190']
    },
    {
      templateId: '30695',
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/mint-moments/Phenomenal-Fritsch-bends-a-dribbler-for-the-win/08_MINT_MMS3_R08_FRITSCH_GOAL",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '31',
      specialSerials: ['1', '190']
    },
    {
      templateId: '30696',
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/mint-moments/Rankine-defies-belief-with-this-boundary-bender/14_MINT_MMS3_R10_RANKINE_GOAL",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '23',
      specialSerials: ['1', '190']
    },
    {
      templateId: '30697',
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/mint-moments/Clutch-Daicos-owns-occasion-with-epic-match-winner/07_MINT_MMS3_R08_DAICOS_GOAL",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '35',
      specialSerials: ['1', '70']
    },
    {
      templateId: '30698',
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/mint-moments/Dancing-Davey's-five-star-foot-work-/12_MINT_MMS3_RD09_DAVEY_GOAL",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '33',
      specialSerials: ['1', '190']
    },
    {
      templateId: '30699',
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/mint-moments/Mesmerising-moves-from-rockstar-Rioli/05_MINT_MMS3_R07_RIOLI_GOAL",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '15',
      specialSerials: ['1', '190']
    },
    {
      templateId: '30700',
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/mint-moments/The-perfect-sit-and-mark-from-Ah-Chee/13_MINT_MMS3_RD10_AH%20CHEE_MARK",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '4',
      specialSerials: ['1', '190']
    },
    {
      templateId: '30701',
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/mint-moments/By-George,-what-a-whopper-of-a-goal-from-Wardlaw/09_MINT_MMS3_R08_WARDLAW_GOAL",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '6',
      specialSerials: ['1', '190']
    },
    {
      templateId: '30702',
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/mint-moments/Treloar-snaps-a-stunner/11_MINT_MMS3_RD09_TRELOAR_GOAL",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '1',
      specialSerials: ['1', '70']
    },
    {
      templateId: '30703',
      baseUrl: "https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/mint-moments/Power-pandemonium-as-Byrne-Jones-bags-winner/15_MINT_MMS3_R10_BYRNE-JONES_GOAL",
      currentContent: 'thumbnail',
      mintNumbers: [],
      highlightedJumper: '33',
      specialSerials: ['1', '70']
    }
  ];

  const [images, setImages] = useState(initialImages);
  const imagesRef = useRef(initialImages);

  useEffect(() => {
    // Configure AWS SDK
    AWS.config.update({
      region: process.env.REACT_APP_AWS_REGION, // Use environment variable for region
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID, // Use environment variable for access key ID
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY, // Use environment variable for secret access key
    });

    const dynamodb = new AWS.DynamoDB();

    const fetchData = async () => {
      const newImages = [...imagesRef.current];
      let isUpdated = false;

      for (let i = 0; i < newImages.length; i++) {
        const image = newImages[i];
        const params = {
          TableName: 'AFLMint',
          IndexName: 'templateId-index', // Specify the GSI index name
          KeyConditionExpression: 'templateId = :tid',
          ExpressionAttributeValues: {
            ':tid': { N: image.templateId }
          },
          ProjectionExpression: 'mintNumber, eventDate' // Retrieve both mintNumber and eventDate attributes
        };

        try {
          const data = await dynamodb.query(params).promise();
          // Map mintNumber and eventDate to an array of objects
          const items = data.Items.map(item => ({
            mintNumber: item.mintNumber.S,
            eventDate: item.eventDate.S
          }));

          // Sort items by eventDate in descending order
          items.sort((a, b) => new Date(b.eventDate) - new Date(a.eventDate));

          // Extract sorted mintNumbers
          const mintNumbers = items.map(item => item.mintNumber);

          if (mintNumbers.toString() !== image.mintNumbers.toString()) {
            newImages[i] = {
              ...newImages[i],
              mintNumbers
            };
            isUpdated = true;
          }
        } catch (err) {
          console.error('Unable to query. Error:', JSON.stringify(err, null, 2));
        }
      }

      if (isUpdated) {
        imagesRef.current = newImages;
        setImages(newImages);
      }
    };

    // Fetch data initially
    fetchData();

    // Set interval to fetch data every 5 minutes
    const intervalId = setInterval(fetchData, 300000); // 300000 ms = 5 minutes

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const handleContentChange = (index, content) => {
    setImages(prevImages => {
      const newImages = [...prevImages];
      newImages[index].currentContent = content;
      return newImages;
    });
  };

  const handleFullscreen = async (event) => {
    const videoElement = event.target;

    try {
      if (videoElement.requestFullscreen) {
        await videoElement.requestFullscreen();
      } else if (videoElement.webkitRequestFullscreen) {
        await videoElement.webkitRequestFullscreen();
      } else if (videoElement.msRequestFullscreen) {
        await videoElement.msRequestFullscreen();
      }
    } catch (error) {
      console.error('Error attempting to enter fullscreen mode:', error);
    }
  };

  const getThumbnailUrl = (baseUrl) => `${baseUrl}_FRONT.jpg`;
  const getContentUrl = (baseUrl, suffix) => `${baseUrl}_${suffix}.jpg`;
  const getVideoUrl = (baseUrl) => `${baseUrl}.mp4`;

  const getSerialRatio = (mintNumbers, specialSerials) => {
    const totalMinted = mintNumbers.length;
    const maxSerial = specialSerials[1]; // The second special serial is the max serial
    return `${totalMinted}/${maxSerial}`;
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="logo-container">
          <img src="/images/aflmint-logo.png" alt="AFL Mint Drops Logo" className="logo" />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h1 className="App-title" style={{ marginRight: '5px' }}>AFL Mint Moments 2024 Rounds 6-10</h1>
          <img
            src="https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/mint-moments/MM24_PACK_ROUNDS_6-10_HQ.png"
            alt="2024 Pack"
            style={{ height: 'auto', width: 'auto', maxHeight: '100px', maxWidth: '100px' }}
          />
        </div>
      </header>
      <div className="image-gallery">
        {images.map((image, index) => (
          <div key={index} className="image-column">
            {image.currentContent === 'thumbnail' && (
              <div>
                <img
                  src={getThumbnailUrl(image.baseUrl)}
                  onMouseEnter={() => handleContentChange(index, `image${index + 1}_1`)}
                  alt={`Thumbnail ${index + 1}`}
                />
                <div className="image-info">
                  <p className={`info-line ${image.mintNumbers.includes(image.highlightedJumper) ? 'highlighted' : ''}`}>
                    Jumper #{image.highlightedJumper}
                    <img src="https://www.aflmint.com.au/assets/Badges/GuernseySerial.svg" alt="Guernsey Serial" style={{ width: '18px', height: '18px', marginLeft: '5px' }} />
                  </p>
                  {image.specialSerials.map(serial => (
                    <p key={serial} className={`info-line ${image.mintNumbers.includes(serial) ? 'highlighted' : ''}`}>
                      Special #{serial}
                    </p>
                  ))}
                </div>
              </div>
            )}
            {image.currentContent === `image${index + 1}_1` && (
              <div>
                <img
                  src={getContentUrl(image.baseUrl, 'BACK1')}
                  alt=""
                  onMouseLeave={() => handleContentChange(index, 'thumbnail')}
                  onClick={() => handleContentChange(index, `image${index + 1}_2`)}
                />
              </div>
            )}
            {image.currentContent === `image${index + 1}_2` && (
              <div>
                <img
                  src={getContentUrl(image.baseUrl, 'BACK2')}
                  alt=""
                  onMouseLeave={() => handleContentChange(index, 'thumbnail')}
                  onClick={() => handleContentChange(index, `video${index + 1}`)}
                />
              </div>
            )}
            {image.currentContent === `video${index + 1}` && (
              <video
                controls
                src={getVideoUrl(image.baseUrl)}
                style={{ outline: 'none' }}
                onMouseLeave={() => handleContentChange(index, 'thumbnail')}
                onClick={handleFullscreen}
              />
            )}
            <div className="mint-numbers">
              <h2>{getSerialRatio(image.mintNumbers, image.specialSerials)}</h2>
              {image.mintNumbers.map((mintNumber, idx) => (
                <div key={idx} className={`mint-number ${mintNumber === image.highlightedJumper || image.specialSerials.includes(mintNumber) ? 'highlight' : ''}`}>
                  <span style={{ color: mintNumber === image.highlightedJumper || image.specialSerials.includes(mintNumber) ? 'blue' : 'inherit', fontWeight: mintNumber === image.highlightedJumper || image.specialSerials.includes(mintNumber) ? 'bold' : 'normal' }}>{mintNumber}</span>
                  {mintNumber === image.highlightedJumper && (
                    <img src="https://www.aflmint.com.au/assets/Badges/GuernseySerial.svg" alt="Guernsey Serial" style={{ width: '18px', height: '18px', marginLeft: '5px' }} />
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MintMoments2024Rounds6to10;